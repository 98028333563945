import { render, staticRenderFns } from "./LessonSituation.vue?vue&type=template&id=08adb26a&scoped=true&lang=pug&"
import script from "./LessonSituation.vue?vue&type=script&lang=ts&"
export * from "./LessonSituation.vue?vue&type=script&lang=ts&"
import style0 from "./LessonSituation.vue?vue&type=style&index=0&id=08adb26a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08adb26a",
  null
  
)

export default component.exports