




















































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentHistoryHeader from '@/components/organisms/StudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentComment from '@/components/organisms/StudentComment.vue'
import HistoryLearningResultStudyContent from '@/components/organisms/HistoryLearningResultStudyContent.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import HistoryLearningResult from '@/components/organisms/HistoryLearningResult.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectWide from '@/components/molecules/SelectWide.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import { LessonType, LessonSubjectType } from '@/models/api/lessons'
import {
  LessonResultResponseType,
  LessonResultConfigType,
  LessonResultStudyContentType,
  LessonResultInformationType,
} from '@/models/api/lessonResults'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    StudentHistoryHeader,
    ColoredBox,
    StudentComment,
    HistoryLearningResultStudyContent,
    LabelBase,
    HistoryLearningResult,
    DatePickerIcon,
    SelectWide,
    SelectSubject,
  },
})
export default class LessonSituation extends Mixins(LocalMoment, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private lessons: LessonType[] = []
  private lessonSelectOptions: { text: string; value: string }[] = []
  private selectedLesson = ''
  private selectedLessonTitle: string | null = null
  private showSubjects: string[] = []
  private selectedSubject = ''

  private studentReviewData: { face: number; comment: string } = { face: 1, comment: '' }

  private studyContents: LessonResultStudyContentType[] = []
  private lessonConfig: LessonResultConfigType | null = null
  private homework: LessonResultInformationType | null = null
  private confirmationTest: LessonResultInformationType | null = null

  private period = ''
  private periodDate: Date | null = null

  private get hasDrillData(): boolean {
    if (this.lessons.length == 0) return false
    if (this.selectedSubject == '') return false
    if (this.selectedLesson == '') return false

    const currentLesson = this.lessons.find((lesson: LessonType) => {
      return lesson.lessonId.toString() === this.selectedLesson
    })
    const currentLessonSubject = currentLesson?.subjects.find((subject: LessonSubjectType) => {
      return subject.code == this.selectedSubject
    })

    return currentLessonSubject?.hasResult || false
  }

  @Watch('selectedLesson')
  onSelectedLessonChange(newLesson: string, oldLesson: string) {
    this.selectedLessonTitle = null
    if (newLesson) {
      const selectedLesson = this.lessonSelectOptions.find((lesson) => lesson.value === newLesson)
      if (selectedLesson) {
        this.selectedLessonTitle = selectedLesson.text
      }

      this.getLessonUsers(newLesson)
      this.setSubjects(newLesson)
      this.getLessonResults()
    }
  }

  @Watch('selectedSubject')
  onSelectedSubjectChange(newSubject: string) {
    if (this.selectedLesson == '' || newSubject == '') return
    this.getLessonResults()
  }

  private setSubjects(lessonId: string): void {
    const lesson = this.lessons.find((lesson: LessonType) => {
      return lesson.lessonId.toString() === lessonId
    })
    if (lesson) {
      this.showSubjects = lesson.subjects.map((subject: LessonSubjectType) => {
        return subject.code
      })
      this.selectedSubject = lesson.subjects.length > 0 ? lesson.subjects[0].code : ''
    } else {
      this.showSubjects = []
      this.selectedSubject = ''
    }
  }

  private onSelectDate(params: { date: moment.Moment }) {
    if (!params.date) {
      alert('日付が選択されていません')
      return
    }
    this.loadLessonHistory(params.date.format('YYYY-MM-DD'))
  }

  private async created(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    this.period = this.defaultPeriod()
    this.periodDate = this.defaultPeriodDate()
    this.loadLessonHistory(this.today().format('YYYY-MM-DD'))
  }

  private defaultPeriod(): string {
    return this.today().format('LL')
  }

  private defaultPeriodDate(): Date {
    return this.today().toDate()
  }

  /**
   * 授業履歴情報の読み込み
   */
  private async loadLessonHistory(date: string) {
    // 学習内容の初期化
    this.studyContents = []
    this.lessonConfig = null
    this.homework = null
    this.confirmationTest = null

    await this.getLessons(date)
  }

  private async getLessons(date: string) {
    await Vue.prototype.$http.httpWithToken
      .get('/lessons', { params: { userId: this.userId, classMode: 'TJ', date: date } })
      .then((res: any) => {
        this.lessons = res.data
        this.lessonSelectOptions = res.data.map((lesson: LessonType) => {
          return { text: lesson.className, value: lesson.lessonId.toString() }
        })
        if (res.data.length > 0) {
          this.selectedLesson = res.data[0].lessonId.toString()
        } else {
          this.selectedLesson = ''
        }
      })
  }

  private async getLessonUsers(lessonId: string) {
    Vue.prototype.$http.httpWithToken
      .get('/lessonUsers/lookBack', { params: { lessonId: lessonId, userId: this.userId, classMode: 'TJ' } })
      .then((res: any) => {
        this.studentReviewData = { face: res.data.lookBackResult, comment: res.data.lookBackComment }
      })
  }

  private async getLessonResults() {
    // 教科が存在しない場合はデータを初期化してreturn
    if (this.selectedSubject == '') {
      this.studyContents = []
      this.lessonConfig = null
      this.homework = null
      this.confirmationTest = null
      return
    }

    await Vue.prototype.$http.httpWithToken
      .get(`/history/lessonResults/TJ/${this.userId}`, {
        params: {
          lessonId: this.selectedLesson,
          subjectCode: this.selectedSubject,
        },
      })
      .then(({ data }: { data: LessonResultResponseType }) => {
        this.studyContents = data.studyContents
        this.lessonConfig = data.lessonConfig
        this.homework = data.homework || null
        this.confirmationTest = data.confirmationTest || null
      })
  }
}
